// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
// ----------------------------------------------------------------------

export const getAccessLogsPagination = createAsyncThunk('/al/getAccessLogsPagination', async ({ offset = 0, limit = 10, userId }) => {
    try {
        const query = `offset=${offset}&limit=${limit}`;
        const response = await axios.get(`/access-logs?${query}`);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

const initialValues = {
    isFetching: false,
    isError: false,
    isSuccess: false,
    data: []
};

const initialState = {
    accessLogsData: { ...initialValues, count: 0 }
};

const slice = createSlice({
    name: 'accesslogs',
    initialState,
    reducers: {
        setShowSummary(state, { payload }) {
            state.showSummary = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setResponse(state, { payload }) {
            state.response = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccessLogsPagination.pending, (state) => {
                state.accessLogsData.isFetching = true;
                state.accessLogsData.isError = false;
                state.accessLogsData.errorMessage = '';
            })
            .addCase(getAccessLogsPagination.fulfilled, (state, { payload }) => {
                state.accessLogsData.data = payload?.data;
                state.accessLogsData.count = payload?.pagination?.count || 0;
                state.accessLogsData.isFetching = false;
            })
            .addCase(getAccessLogsPagination.rejected, (state, { payload }) => {
                state.accessLogsData.isFetching = false;
                state.accessLogsData.isError = true;
                state.accessLogsData.errorMessage = payload;
            });
    }
});

export const accessLogsSelector = (state) => state.accesslogs;
export default slice.reducer;
export const { setShowSummary, setLoading, setResponse } = slice.actions;

// ----------------------------------------------------------------------
