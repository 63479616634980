import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import GuestGuard from 'utils/route-guard/GuestGuard';

// login routing
const SharedOutputData = Loadable(lazy(() => import('views/pages/shared/index')));
// ==============================|| AUTH ROUTING ||============================== //

const PublicRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/shared/:id',
            element: <SharedOutputData />
        },
        {
            path: '/shared/:id?PUBLIC=true',
            element: <SharedOutputData />
        }
    ]
};

export default PublicRoutes;
