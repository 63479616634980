// routing
import Routes from 'routes';
import ReactGA from 'react-ga';
// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { titleCase } from 'utils/helpers';
import usePageTracking from 'hooks/useAnalytics';

// ==============================|| APP ||============================== //

ReactGA.initialize(process.env.REACT_APP_ANALYTICS);

const App = () => {
    const { pathname } = useLocation();
    usePageTracking();

    useEffect(() => {
        const page = titleCase(pathname.split('/')[1].replace('-', ' '));
        const title = `Silver Stay - ${page}`;
        document.title = page ? title : 'Silver Stay';
        ReactGA.pageview(pathname);
    }, [pathname]);

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <Routes />
                            <Snackbar />
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
