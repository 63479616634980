import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// sample page routing
const Add = Loadable(lazy(() => import('views/pages/list/form/index')));
const Edit = Loadable(lazy(() => import('views/pages/list/form/index')));
const View = Loadable(lazy(() => import('views/pages/list/details/index')));
const SamplePage = Loadable(lazy(() => import('views/pages/list')));
const UsersPage = Loadable(lazy(() => import('views/pages/users/index')));
const SavedFilterPage = Loadable(lazy(() => import('views/pages/saved-filters/index')));
const SharedOutputPage = Loadable(lazy(() => import('views/pages/generated-options/index')));
const BatchUpload = Loadable(lazy(() => import('views/pages/bulk-upload')));
const ViewCards = Loadable(lazy(() => import('views/pages/saved-filters/card')));
const TableView = Loadable(lazy(() => import('views/pages/saved-filters/filterTable')));
const SharedQuoteTable = Loadable(lazy(() => import('views/pages/generated-options/sharedQuotesTable')));
const SharedCards = Loadable(lazy(() => import('views/pages/generated-options/card')));
const AccountSettings = Loadable(lazy(() => import('views/pages/account-settings')));
const AccessLogs = Loadable(lazy(() => import('views/pages/access-logs')));
const ChangePassword = Loadable(lazy(() => import('views/pages/account-settings/ChangePassword')));
const ErrorPage = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/',
            element: <SamplePage />
        },
        {
            path: '/facilities',
            element: <SamplePage />
        },
        {
            path: '/users',
            element: <UsersPage />
        },
        {
            path: '/add',
            element: <Add />
        },
        {
            path: '/facilities/view/:id',
            element: <View />
        },
        {
            path: '/facilities/edit/:id',
            element: <Edit />
        },
        {
            path: '/filters',
            element: <SavedFilterPage />,
            children: [
                {
                    path: 'table',
                    element: <TableView />
                },
                {
                    path: 'cards',
                    element: <ViewCards />
                }
            ]
        },
        {
            path: '/generated-options',
            element: <SharedOutputPage />,
            children: [
                {
                    path: 'table',
                    element: <SharedQuoteTable />
                },
                {
                    path: 'cards',
                    element: <SharedCards />
                }
            ]
        },
        {
            path: '/bulk-upload',
            element: <BatchUpload />
        },
        {
            path: '/user/account-settings',
            element: <AccountSettings />,
            children: [
                {
                    path: 'change-password',
                    element: <ChangePassword />
                }
            ]
        },
        {
            path: '/access-logs',
            element: <AccessLogs />
        },
        {
            path: '*',
            element: <ErrorPage />
        }
    ]
};

export default MainRoutes;
