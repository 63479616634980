// third-party
import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------
const initialState = {
    showSummary: false,
    loading: false,
    response: []
};

const slice = createSlice({
    name: 'bulkupload',
    initialState,
    reducers: {
        setShowSummary(state, { payload }) {
            state.showSummary = payload;
        },
        setLoading(state, { payload }) {
            state.loading = payload;
        },
        setResponse(state, { payload }) {
            state.response = payload;
        }
    }
});

export const bulkuploadSelector = (state) => state.bulkupload;
export default slice.reducer;
export const { setShowSummary, setLoading, setResponse } = slice.actions;

// ----------------------------------------------------------------------
