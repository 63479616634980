// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { dispatch } from 'store';
import axios from 'utils/axios';

// project imports
import { v4 as uuidv4 } from 'uuid';
import { openSnackbar } from './snackbar';

export const postSaveFilter = createAsyncThunk('/filter/postSaveFilter', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/save-filter`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const patchUpdateFilter = createAsyncThunk('/filter/postUpdateFilter', async ({ id, obj }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`/save-filter/${id}`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getSavedFiltersList = createAsyncThunk('/filter/getSavedFiltersList', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/save-filter/?limit=${obj?.limit ?? 30}&offset=${obj?.offset ?? 0}`);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const deleteSavedFiltersList = createAsyncThunk('/filter/deleteSavedFiltersList', async ({ id }, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/save-filter/${id}`);
        const { data } = response;
        await dispatch(
            openSnackbar({
                open: true,
                message: 'Operation was successful ',
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: true
            })
        );
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});
// ----------------------------------------------------------------------
const initialValue = {
    data: null,
    isFetching: false,
    errorMessage: '',
    isError: false
};

const initialState = {
    error: null,
    savedFiltersList: { ...initialValue, data: Array(1), pagination: { count: 10, offset: 0, limit: 10 } },
    savedFiltersResultsList: [],
    appliedFilters: { ...initialValue, data: {} },
    saveFilterModal: false,
    isCloneFilter: false
};

const slice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        setSaveFilterModal(state, { payload }) {
            state.saveFilterModal = payload;
        },
        setIsCloneFilter(state, { payload }) {
            state.isCloneFilter = payload;
        },
        addFilterToList(state, { payload }) {
            const genID = uuidv4();
            state.savedFiltersList.unshift({
                id: genID,
                items: payload.items,
                createdAt: new Date().toDateString(),
                name: payload.name,
                linkOperator: payload.linkOperator
            });
        },
        updateFilterToList(state, { payload }) {
            state.savedFiltersList = state.savedFiltersList.map((filter) => {
                if (filter.id === payload?.id) {
                    return payload;
                }
                return filter;
            });
        },
        setAppliedFilter(state, { payload }) {
            state.appliedFilters.data = payload;
        },
        clearAppliedFilter(state) {
            state.appliedFilters.data = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postSaveFilter.pending, (state) => {
                state.appliedFilters.isFetching = true;
                state.appliedFilters.isError = false;
                state.appliedFilters.errorMessage = '';
                state.appliedFilters.errorMessage = '';
            })
            .addCase(postSaveFilter.fulfilled, (state, { payload }) => {
                state.appliedFilters.data = payload;
                state.appliedFilters.isError = false;
                state.appliedFilters.isFetching = false;
            })
            .addCase(postSaveFilter.rejected, (state, { payload }) => {
                state.appliedFilters.isFetching = false;
                state.appliedFilters.isError = true;
                state.appliedFilters.errorMessage = payload;
            })
            // =============================patchUpdateFilter=================================
            .addCase(patchUpdateFilter.pending, (state) => {
                state.appliedFilters.isFetching = true;
                state.appliedFilters.isError = false;
                state.appliedFilters.errorMessage = '';
            })
            .addCase(patchUpdateFilter.fulfilled, (state, { payload }) => {
                state.appliedFilters.data = payload;
                state.appliedFilters.isFetching = false;
            })
            .addCase(patchUpdateFilter.rejected, (state, { payload }) => {
                state.appliedFilters.isFetching = false;
                state.appliedFilters.isError = true;
                state.appliedFilters.errorMessage = payload;
            })
            // =================================getSavedFiltersList================================
            .addCase(getSavedFiltersList.pending, (state) => {
                state.savedFiltersList.isFetching = true;
                state.savedFiltersList.isError = false;
                state.savedFiltersList.errorMessage = '';
            })
            .addCase(getSavedFiltersList.fulfilled, (state, { payload }) => {
                state.savedFiltersList.data = payload?.data;
                state.savedFiltersList.pagination = payload?.pagination;
                state.savedFiltersList.isFetching = false;
            })
            .addCase(getSavedFiltersList.rejected, (state, { payload }) => {
                state.savedFiltersList.isFetching = false;
                state.savedFiltersList.isError = true;
                state.savedFiltersList.errorMessage = payload;
            });
    }
});

export const filterSelector = (state) => state.filter;
export default slice.reducer;
export const {
    setSaveFilterModal,
    setIsCloneFilter,
    deleteFilter,
    addFilterToList,
    setAppliedFilter,
    clearAppliedFilter,
    updateFilterToList
} = slice.actions;

// ----------------------------------------------------------------------
