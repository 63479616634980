/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const GuestGuard = ({ children }) => {
    const location = useLocation();
    const query = useQuery();
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes('shared')) {
            if (isLoggedIn && !query.get('PUBLIC')) {
                navigate(location.pathname);
            } else {
                navigate(`${location.pathname}?PUBLIC=true`);
            }
            // navigate(location.pathname);
        } else if (isLoggedIn) {
            navigate(DASHBOARD_PATH, { replace: true });
        }
    }, [isLoggedIn, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
