// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
// import chatReducer from './slices/chat';
// import calendarReducer from './slices/calendar';
// import mailReducer from './slices/mail';
import userReducer from './slices/user';
import facilityReducer from './slices/facilities';
import filterReducer from './slices/filter';
import kanbanReducer from './slices/kanban';
import menuReducer from './slices/menu';
import bulkuploadReducer from './slices/bulk-upload';
import sharedOutputReducer from './slices/generated-options';
import accessLogsReducer from './slices/access-logs';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    // cart: cartReducer,
    kanban: kanbanReducer,
    // customer: customerReducer,
    // contact: contactReducer,
    // product: productReducer,
    // chat: chatReducer,
    // calendar: calendarReducer,
    // mail: mailReducer,
    menu: menuReducer,
    user: persistReducer(
        {
            key: 'user',
            storage,
            keyPrefix: 'ss-'
        },
        userReducer
    ),
    sharedoutput: sharedOutputReducer,
    accesslogs: accessLogsReducer,
    filter: filterReducer,
    bulkupload: bulkuploadReducer,
    facility: facilityReducer
});

export default reducer;
