// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';

// -------------------------------Thunk---------------------------------------
// this api will fetch list data
export const postFacilitiesSearch = createAsyncThunk('/facilities/postFacilitiesSearch', async (obj) => {
    try {
        // obj?.items?.map((item) => ({ ...item, value: Number(item.value) || item.value }))
        const response = await axios.post(
            `/facilities/search?limit=${obj?.limit ?? 10}&offset=${obj?.offset ?? 0}&sortOrder=${
                obj?.sortOrder.toUpperCase() || 'DESC'
            }&sortBy=${obj?.sortBy ?? 'preferredProviderIndicatorCalculated'}`,
            {
                items: obj?.items ?? [],
                linkOperator: obj?.linkOperator ?? 'and',
                searchQuery: obj?.searchQuery ?? ''
            }
        );
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const getFacilitiesFields = createAsyncThunk('/facilities/getFacilitiesFields', async () => {
    try {
        const response = await axios.get('/facilities/all-fields');
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const getFacilityById = createAsyncThunk('/facilities/getFacilityById', async (id) => {
    try {
        const response = await axios.get(`/facilities/${id}`);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const postSaveFacilitiesFields = createAsyncThunk('/facilities/postSaveFacilitiesFields', async (obj) => {
    try {
        const response = await axios.post('/facilities', obj);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const patchUpdateFacilitiesFields = createAsyncThunk('/facilities/patchUpdateFacilitiesFields', async (obj) => {
    try {
        const response = await axios.patch(`/facilities/${obj?.id}`, obj?.data);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const postCheckZipcode = createAsyncThunk('/facilities/postCheckZipcode', async (obj) => {
    try {
        const response = await axios.post('/facilities/check-zipcode', obj);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

/** Delete Facilities */
export const deleteFacilities = createAsyncThunk('/facilities/deleteFacilities', async (obj) => {
    try {
        let data;
        if (obj.deleteSingle) {
            const response = await axios.delete(`/facilities/${obj.ids}`);
            data = response;
        }
        if (!obj.deleteSingle) {
            const deleteMultiple = obj.ids.map((id) => axios.delete(`/facilities/${id}`));
            data = await Promise.all(deleteMultiple);
        }
        return data;
    } catch (e) {
        return e;
    }
});

// ----------------------------------------------------------------------
const initialValue = {
    data: null,
    isFetching: false,
    errorMessage: '',
    isError: false
};

const initialState = {
    error: null,
    isFormEdit: false,
    facilityFieldsDeclarationOriginal: {}, // this state should be used for checking type of specific field
    facilityFieldsDeclaration: { ...initialValue, data: [] }, // this state is just schema for form
    facilitiesFormData: [], // this state will be used for form, for new,edit and view facility
    facilitiesListData: { ...initialValue, data: { data: [], pagination: {} } },
    postFacilityState: { ...initialValue },
    rows: [],
    columns: [],
    facilityEditData: { ...initialValue, data: {} },
    isFetching: false,
    errorMessage: '',
    isError: false,
    newFacilitiesList: [],
    showProgress: { state: false, type: '' }
};
const newFacilitySchema = {
    id: '',
    communityName: null,
    joinedDate: null,
    website: null,
    streetAddress: null,
    status: 'New',
    createdAt: new Date(),
    updatedAt: null,
    deletedAt: null,
    preferredProviderIndicatorManual: null,
    preferredProviderIndicatorCalculated: null,
    vacanciesLastUpdated: null,
    vacanciesDetails: null,
    communityNotes: null,
    neighborhood: null,
    narrative: null,
    preferredModeOfCommunication: null,
    linkToRa: null,
    preferredPharmacy: null,
    preferredHomeHealthHospicePalliativeAgency: null,
    mobileLabAffilliate: null,
    pcp: null,
    dn: null,
    longTermRate: null,
    shortTermRate: null,
    signedContract: null,
    licenseLevel: null,
    bedCapacity: null,
    roomBreakdown: null,
    waiver: null,
    subsidy: null,
    licenseNumber: null,
    additionalLicenseDetails: null,
    longTermCommunityFee: null,
    longTermLowestRateConsidered: null,
    otherFees: null,
    aestheticallyPleasingRating: null,
    problem_solver_rating: null,
    bedsideMannersRating: null,
    wheelchairFriendly: null,
    stairLift: null,
    hoyerLift: null,
    twoPersonAssist: null,
    awakeOvernightStaff: null,
    feedingTube: null,
    tpn: null,
    woundStage3Plus: null,
    woundCareRating: null,
    zipCode: 20657, // remove this once backend fixed zipcode -> zipCode
    city: null,
    county: null,
    state: null
};

const slice = createSlice({
    name: 'facility',
    initialState,
    reducers: {
        setShowProgress(state, { payload }) {
            state.showProgress = payload;
        },
        clearFacilitiesListData(state) {
            state.facilitiesListData.data.data = [];
            state.facilitiesListData.data.pagination = {};
        },
        setRows(state, { payload }) {
            state.rows = payload;
        },
        setColumns(state, { payload }) {
            state.columns = payload;
        },
        setRowForEdit(state, { payload }) {
            state.facilityEditData.data = payload;
        },
        setFacilityFormData(state, { payload }) {
            state.facilitiesFormData = payload;
        },
        changeValueByKey(state, { payload }) {
            state.facilitiesFormData = state.facilitiesFormData.map((item) => {
                if (item.key === payload.key) {
                    return {
                        ...item,
                        value: payload.value
                    };
                }
                return item;
            });
        },
        addMediaField(state) {
            state.facilitiesFormData = state.facilitiesFormData.map((item) => {
                if (item.key === 'media') {
                    return {
                        ...item,
                        value: [...item.value, { label: '', link: '' }]
                    };
                }
                return item;
            });
        },
        removeMediaField(state, { payload }) {
            state.facilitiesFormData = state.facilitiesFormData.map((item) => {
                if (item.key === 'media') {
                    return {
                        ...item,
                        value: item.value.length === 1 ? [{ label: '', link: '' }] : item.value.filter((i, ind) => ind !== payload.index)
                    };
                }
                return item;
            });
        },
        changeValueByKeyForMedia(state, { payload }) {
            state.facilitiesFormData = state.facilitiesFormData.map((item) => {
                if (item.key === payload.key) {
                    return {
                        ...item,
                        value: item.value.map((i, ind) => {
                            if (ind === payload.index) {
                                return {
                                    ...i,
                                    [payload.input]: payload.value
                                };
                            }
                            return i;
                        })
                    };
                }
                return item;
            });
        },
        addNewRowInData(state, { payload }) {
            state.newFacilitiesList.unshift({ ...newFacilitySchema, ...payload, isNew: true });
        },
        updateNewRowInData(state, { payload }) {
            const rowToEdit = state.newFacilitiesList.filter((row) => row.id === payload.id);
            const rowToAdd = rowToEdit.map((item) => ({ ...item, ...payload }));
            const allRowsExceptThisRow = state.newFacilitiesList?.filter((row) => row.id !== payload.id);
            const completeRow = [...rowToAdd, ...allRowsExceptThisRow];
            state.newFacilitiesList = completeRow;
        },
        setIsFormEdit(state, { payload }) {
            state.isFormEdit = payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postFacilitiesSearch.pending, (state) => {
                state.facilitiesListData.isFetching = true;
                state.facilitiesListData.isError = false;
                state.facilitiesListData.errorMessage = '';
            })
            .addCase(postFacilitiesSearch.fulfilled, (state, { payload }) => {
                state.facilitiesListData.isFetching = false;
                if (!payload.errorMessage) {
                    if (payload?.data?.length > 0) {
                        state.facilitiesListData.data.data = state.facilitiesListData.data?.data?.slice(
                            0,
                            payload?.pagination?.offset ?? 0
                        );
                        state.facilitiesListData.data?.data?.push(...payload?.data);
                    } else {
                        state.facilitiesListData.data.data = [];
                    }
                    state.facilitiesListData.data.pagination = payload.pagination;
                } else {
                    state.facilitiesListData.data.data = payload?.data || [];
                    state.facilitiesListData.data.pagination = { count: 0, offset: 0, limit: 0 };
                }
            })
            .addCase(postFacilitiesSearch.rejected, (state, { payload }) => {
                state.facilitiesListData.isFetching = false;
                state.facilitiesListData.isError = true;
                state.facilitiesListData.errorMessage = payload;
            })
            // =============================  getFacilitiesFields =============================
            .addCase(getFacilitiesFields.pending, (state) => {
                state.facilityFieldsDeclaration.isFetching = true;
                state.facilityFieldsDeclaration.isError = false;
                state.facilityFieldsDeclaration.errorMessage = '';
            })
            .addCase(getFacilitiesFields.fulfilled, (state, { payload }) => {
                const data = Object.values(payload).map((obj, index) => ({
                    ...obj,
                    value:
                        obj.type.toLowerCase() === 'checkbox'
                            ? false
                            : obj?.type.toLowerCase() === 'json'
                            ? [{ label: '', link: '' }]
                            : null,
                    isMandatory: obj?.type.toLowerCase() === 'json' ? false : obj.isMandatory,
                    key: Object.keys(payload)[index]
                }));
                state.facilityFieldsDeclaration.data = [...data];
                // state.facilitiesFormData.data = data;
                state.facilityFieldsDeclarationOriginal = payload;
                state.facilityFieldsDeclaration.isFetching = false;
            })
            .addCase(getFacilitiesFields.rejected, (state, { payload }) => {
                state.facilityFieldsDeclaration.isFetching = false;
                state.facilityFieldsDeclaration.isError = true;
                state.facilityFieldsDeclaration.errorMessage = payload;
            })

            // =============================  getFacilityById =============================
            .addCase(getFacilityById.pending, (state) => {
                state.facilityEditData.isFetching = true;
                state.facilityEditData.isError = false;
                state.facilityEditData.errorMessage = '';
            })
            .addCase(getFacilityById.fulfilled, (state, { payload }) => {
                state.facilityEditData.data = payload;
                state.facilityEditData.isFetching = false;
                state.facilityEditData.isError = false;
                state.facilityEditData.errorMessage = '';
                const rowTransform = state.facilityFieldsDeclaration?.data.map((field) => ({
                    ...field,
                    value: field.key === 'media' && !payload[field.key] ? [{ label: '', link: '' }] : payload[field.key]
                }));
                rowTransform.unshift({
                    key: 'id',
                    fieldTitle: 'id',
                    fieldDescription: '',
                    type: 'temp',
                    isMandatory: null,
                    category: 'temp',
                    value: payload?.id
                });
                state.facilitiesFormData = rowTransform;
            })
            .addCase(getFacilityById.rejected, (state, { payload }) => {
                state.facilityEditData.isFetching = false;
                state.facilityEditData.isError = true;
                state.facilityEditData.errorMessage = payload;
            })
            // =============================  postCheckZipCodes =============================
            // .addCase(postCheckZipcode.pending, (_state) => {
            //     // state.facilityFieldsDeclaration.isFetching = true;
            //     // state.facilityFieldsDeclaration.isError = false;
            //     // state.facilityFieldsDeclaration.errorMessage = '';
            // })
            .addCase(postCheckZipcode.fulfilled, (state, { payload }) => {
                console.log('Payload returned from zipcode', payload);
                state.facilitiesFormData = state.facilitiesFormData?.map((item) => {
                    if (item.key === 'city') {
                        return {
                            ...item,
                            value: payload.cityName || item.value
                        };
                    }
                    if (item.key === 'county') {
                        return {
                            ...item,
                            value: payload.county || item.value
                        };
                    }
                    if (item.key === 'state') {
                        return {
                            ...item,
                            value: payload.state || item.value
                        };
                    }
                    return item;
                });
                console.log('Payload returned After zipcode', state.facilitiesFormData);
            })
            // .addCase(postCheckZipcode.rejected, (state) => {
            //     // state.facilityFieldsDeclaration.isFetching = false;
            //     // state.facilityFieldsDeclaration.isError = true;
            //     // state.facilityFieldsDeclaration.errorMessage = payload;
            // })
            // =============================  postSaveFacilitiesFields =============================
            .addCase(postSaveFacilitiesFields.pending, (state) => {
                state.facilityFieldsDeclaration.isFetching = true;
                state.facilityFieldsDeclaration.isError = false;
                state.facilityFieldsDeclaration.errorMessage = '';
            })
            .addCase(postSaveFacilitiesFields.fulfilled, (state, { payload }) => {
                if (payload.error) {
                    state.facilityFieldsDeclaration.errorMessage = payload;
                    state.facilityFieldsDeclaration.isFetching = false;
                    state.facilityFieldsDeclaration.isError = true;
                } else {
                    state.newFacilitiesList.unshift(payload);
                    state.facilitiesFormData = state.facilitiesFormData.map((item) => ({
                        ...item,
                        value: payload[item.key] || item.value
                    }));
                    state.facilitiesFormData.unshift({
                        key: 'id',
                        fieldTitle: 'id',
                        fieldDescription: '',
                        type: 'temp',
                        isMandatory: null,
                        category: 'temp',
                        value: payload.id
                    });
                }
            })
            .addCase(postSaveFacilitiesFields.rejected, (state, { payload }) => {
                state.facilityFieldsDeclaration.isFetching = false;
                state.facilityFieldsDeclaration.isError = true;
                state.facilityFieldsDeclaration.errorMessage = payload;
            })
            // =============================  patchUpdateFacilitiesFields =============================
            .addCase(patchUpdateFacilitiesFields.pending, (state) => {
                state.facilityFieldsDeclaration.isFetching = true;
                state.facilityFieldsDeclaration.isError = false;
                state.facilityFieldsDeclaration.errorMessage = '';
            })
            .addCase(patchUpdateFacilitiesFields.fulfilled, (state, { payload }) => {
                if (payload.error) {
                    state.facilityFieldsDeclaration.isFetching = false;
                    state.facilityFieldsDeclaration.isError = true;
                    state.facilityFieldsDeclaration.errorMessage = payload;
                } else {
                    state.facilityFieldsDeclaration.isFetching = false;
                    state.facilityFieldsDeclaration.isError = false;
                    state.facilityFieldsDeclaration.errorMessage = {};
                }
            })
            .addCase(patchUpdateFacilitiesFields.rejected, (state, { payload }) => {
                state.facilityFieldsDeclaration.isFetching = false;
                state.facilityFieldsDeclaration.isError = true;
                state.facilityFieldsDeclaration.errorMessage = payload;
            });
    }
});

// Reducer
export const facilitySelector = (state) => state.facility;
export default slice.reducer;
export const {
    setShowProgress,
    clearFacilitiesListData,
    setRows,
    setColumns,
    setFacilityFormData,
    addNewRowInData,
    updateNewRowInData,
    setRowForEdit,
    changeValueByKey,
    changeValueByKeyForMedia,
    addMediaField,
    removeMediaField,
    setIsFormEdit
} = slice.actions;
// ----------------------------------------------------------------------
