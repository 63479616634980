// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/axios';
import { dispatch } from '../index';

export const postInviteUser = createAsyncThunk('/user/postInviteUser', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/users/invite`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const patchInviteUser = createAsyncThunk('/user/patchInviteUser', async ({ id, obj }, { rejectWithValue }) => {
    try {
        const response = await axios.patch(`/users/${id}`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getUsersList = createAsyncThunk('/user/getUsersList', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.get(`/users/?limit=${obj?.limit ?? 30}&offset=${obj?.offset ?? 0}`);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const deleteUserByID = createAsyncThunk('/user/deleteUserByID', async (id, { rejectWithValue }) => {
    try {
        const response = await axios.delete(`/users/${id}`);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const postResetPassword = createAsyncThunk('/user/postResetPassword', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/users/reset-password`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const postchangePassword = createAsyncThunk('/user/postchangePassword', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/users/change-password`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const postForgotPassword = createAsyncThunk('/user/postForgotPassword', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/users/forgot-password`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const postCheckTokenValidity = createAsyncThunk('/user/postCheckTokenValidity', async (obj, { rejectWithValue }) => {
    try {
        const response = await axios.post(`/users/reset-token-validity`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

const initialValue = {
    data: null,
    isFetching: false,
    errorMessage: '',
    isError: false
};

// ----------------------------------------------------------------------
const initialState = {
    error: null,
    isFetching: false,
    isError: false,
    errorMessage: '',
    inviteUser: { ...initialValue },
    usersInviteList: {
        ...initialValue,
        data: [],
        pagination: { count: 10, limit: 10, offset: 0 }
    },
    userFormData: { firstName: '', lastName: '', email: '' },
    userFormMode: 'new',
    tokenValidity: { ...initialValue }
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },
        // set UserFormData
        setUserFormData(state, action) {
            state.userFormData = action.payload;
        },
        setUserFormMode(state, action) {
            state.userFormMode = action.payload;
        },
        // set Users List
        setUsersList(state, { payload }) {
            if (state.userFormMode === 'new') {
                state.usersList = [
                    ...state.usersList,
                    {
                        id: state.usersList?.length + 1 ?? 1,
                        avatar: 'user-4.png',
                        fname: payload.fname,
                        lname: payload.lname,
                        verify: 1,
                        email: payload.email,
                        location: payload.address,
                        status: 'Invited'
                    }
                ];
            } else {
                state.usersList = state.usersList.map((user) => {
                    if (user.id === payload.id) {
                        return payload;
                    }
                    return user;
                });
            }
        },
        // Delete users
        deleteUser(state, { payload }) {
            const filterUsers = state.usersList.filter((item) => item?.id !== payload);
            state.usersList = filterUsers;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postInviteUser.pending, (state) => {
                state.inviteUser.isFetching = true;
                state.inviteUser.isError = false;
                state.inviteUser.errorMessage = '';
            })
            .addCase(postInviteUser.fulfilled, (state, { payload }) => {
                state.inviteUser.data = payload;
                state.inviteUser.isError = false;
                state.inviteUser.isFetching = false;
            })
            .addCase(postInviteUser.rejected, (state, { payload }) => {
                state.inviteUser.isFetching = false;
                state.inviteUser.isError = true;
                state.inviteUser.errorMessage = payload;
            })
            // ==============================patchInviteUser===================================
            .addCase(patchInviteUser.pending, (state) => {
                state.isFetching = true;
                state.isError = false;
                state.errorMessage = '';
            })
            .addCase(patchInviteUser.fulfilled, (state) => {
                // state.data = payload;
                state.isError = false;
                state.isFetching = false;
            })
            .addCase(patchInviteUser.rejected, (state, { payload }) => {
                state.isFetching = false;
                state.isError = true;
                state.errorMessage = payload;
            })
            // ==============================getUsersList===================================
            .addCase(getUsersList.pending, (state) => {
                state.usersInviteList.isFetching = true;
                state.usersInviteList.isError = false;
                state.usersInviteList.errorMessage = '';
            })
            .addCase(getUsersList.fulfilled, (state, { payload }) => {
                state.usersInviteList.data = payload.data;
                state.usersInviteList.pagination = payload.pagination;
                state.usersInviteList.isError = false;
                state.usersInviteList.isFetching = false;
            })
            .addCase(getUsersList.rejected, (state, { payload }) => {
                state.usersInviteList.isFetching = false;
                state.usersInviteList.isError = true;
                state.usersInviteList.errorMessage = payload;
            })
            // ==============================postCheckTokenValidity===================================
            .addCase(postCheckTokenValidity.pending, (state) => {
                state.tokenValidity.isFetching = true;
                state.tokenValidity.isError = false;
                state.tokenValidity.errorMessage = '';
            })
            .addCase(postCheckTokenValidity.fulfilled, (state, { payload }) => {
                state.tokenValidity.data = payload;
                state.tokenValidity.isError = false;
                state.tokenValidity.isFetching = false;
            })
            .addCase(postCheckTokenValidity.rejected, (state, { payload }) => {
                state.tokenValidity.isFetching = false;
                state.tokenValidity.isError = true;
                state.tokenValidity.errorMessage = payload;
            });
    }
});

// Reducer
export const userSelector = (state) => state.user;
export default slice.reducer;
export const { setUsersList, deleteUser, setUserFormData, setUserFormMode } = slice.actions;
// ----------------------------------------------------------------------

// extra reducers
export function inviteUserFormHandler(data) {
    return async () => {
        try {
            // const response = await axios.get('/api/user-list/s1/list');
            dispatch(slice.actions.setUsersList(data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getUsersListStyle2() {
    return async () => {
        try {
            const response = await axios.get('/api/user-list/s2/list');
            dispatch(slice.actions.getUsersListStyle2Success(response.data.users_s2));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
