/* eslint-disable no-plusplus */
export const titleCase = (string) => {
    if (!string) {
        return '';
    }
    const sentence = string.trim().toLowerCase().split(' ');
    for (let i = 0; i < sentence.length; i++) {
        sentence[i] = sentence[i][0]?.toUpperCase() + (sentence[i]?.slice(1) ?? '');
    }
    return sentence.toLocaleString()?.replace(',', ' ');
};
export const camelToLowerCase = (str) => str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

export const underscoreToTitleCase = (str) => {
    if (Number(str) === 0 || Number(str) === 1 || Number(str) === -1) {
        return str;
    }
    if (!str) {
        return '';
    }
    if (str?.toLowerCase() === 'y') {
        return 'Yes';
    }
    if (str?.toLowerCase() === 'n') {
        return 'No';
    }
    let i;
    const frags = str.split('_');
    if (frags.length === 1) {
        frags[0] = frags[0].replace(/([A-Z]|(\d+))/g, ' $1');
    }
    for (i = 0; i < frags.length; i++) {
        frags[i] = frags[i].charAt(0)?.toUpperCase() + frags[i].slice(1);
    }
    return frags.join(' ');
};

export const handleDropDownOptionsViewValue = (value) => {
    if (value === 'we_dont_know') {
        return '';
    }
    return underscoreToTitleCase(value);
};

export const validateAndRefineLink = (str) => {
    const matches = str.match(/\bhttps?:\/\/\S+/gm);
    if (matches) {
        return str;
    }
    return `http://${str}`;
};
export const numberWithDollarNCommas = (x) => {
    x = x.toLocaleString();
    return x ? `$${x}` : '';
};

export const camelCase = (str) =>
    str
        .replace(/\s(.)/g, (a) => a.toUpperCase())
        .replace(/\s/g, '')
        .replace(/^(.)/, (b) => b.toLowerCase());
