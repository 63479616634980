// material-ui
import logo from '../assets/images/ss-icon.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({ height = 60 }) => <img className="logo-image" src={logo} alt="silver stay" height={height} />;

export default Logo;
