/**
 * axios setup to use mock service
 */

import axios from 'axios';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';
import { BASE_URL } from '../config';

const api = axios.create({
    baseURL: BASE_URL,
    timeout: 30000
});

// Add a request interceptor
// api.interceptors.request.use(
//     async (config) => {
//         // Make an additional API call before the original request
//         try {
//             const newRes = await axios.get(`${config?.baseURL}facilities/all-fields`, { headers: config?.headers });
//         } catch (error) {
//             // Handle the error if the additional API call fails
//             console.log('==============error in interceptor======================');
//             console.log(error);
//             console.log('====================================');
//         }

//         return config;
//     },
//     (error) => Promise.reject(error)
// );

// interceptor for http
api.interceptors.response.use(
    async (response) => {
        // if (response.status === 201) {
        //     await dispatch(
        //         openSnackbar({
        //             open: true,
        //             message: 'Operation was successful ',
        //             variant: 'alert',
        //             alert: {
        //                 color: 'success'
        //             },
        //             close: true
        //         })
        //     );
        // }

        if (response.status >= 400) {
            await dispatch(
                openSnackbar({
                    open: true,
                    message: 'Some Error Occured',
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
        return response;
    },
    async (error) => {
        if (error.response && error.response.status) {
            await dispatch(
                openSnackbar({
                    open: true,
                    message:
                        error.response?.status === 404
                            ? 'Record not found'
                            : error.response?.data.message.toLowerCase() === 'unauthorized' ||
                              error.response?.data.message.toLowerCase() === 'unauthorised'
                            ? 'Invalid credentials, please try again.'
                            : error.response?.data.message ?? error.response?.statusText ?? error.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        } else {
            await dispatch(
                openSnackbar({
                    open: true,
                    message: error.message,
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: true
                })
            );
        }
        return Promise.reject(error.response && error.response.data);
    }
);

export default api;
