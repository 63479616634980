import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
import { dispatch } from 'store';
import { activeItem } from 'store/slices/menu';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ height = 60 }) => (
    <Link
        className="logo-sharedoutput"
        component={RouterLink}
        to={DASHBOARD_PATH}
        onClick={() => {
            dispatch(activeItem([DASHBOARD_PATH?.split('/')[1]]));
        }}
    >
        <Logo height={height} />
    </Link>
);

export default LogoSection;
