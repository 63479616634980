// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBuildingCommunity,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconFilter,
    IconShare,
    IconFileUpload,
    IconHistory
    // IconPresentationAnalytics
} from '@tabler/icons';
// constant
const icons = {
    IconBuildingCommunity,
    IconHelp,
    IconSitemap,
    IconUsers,
    IconFilter,
    IconShare,
    IconFileUpload,
    IconHistory
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'facilities',
            title: <FormattedMessage id="facilities" />,
            type: 'item',
            url: '/facilities',
            icon: icons.IconBuildingCommunity,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            url: '/users',
            icon: icons.IconUsers,
            breadcrumbs: false
        },
        {
            id: 'filters',
            title: <FormattedMessage id="saved-filters" />,
            type: 'item',
            url: '/filters',
            icon: icons.IconFilter,
            breadcrumbs: false
        },
        {
            id: 'generated-options',
            title: <FormattedMessage id="generated-options" />,
            type: 'item',
            url: '/generated-options',
            icon: icons.IconShare,
            breadcrumbs: false
        },
        {
            id: 'bulk-upload',
            title: <FormattedMessage id="bulk-upload" />,
            type: 'item',
            url: '/bulk-upload',
            icon: icons.IconFileUpload,
            breadcrumbs: false
        },
        {
            id: 'access-logs',
            title: <FormattedMessage id="access-logs" />,
            type: 'item',
            url: '/access-logs',
            icon: icons.IconHistory,
            breadcrumbs: false
        }
    ]
};

export default other;
