// third-party
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'utils/axios';
import moment from 'moment';

// project imports
import { dispatch } from 'store';
import { openSnackbar } from './snackbar';
// ----------------------------------------------------------------------
export const getGenerateOptionsList = createAsyncThunk('/go/getGenerateOptionsList', async (obj) => {
    try {
        const response = await axios.get(`/generate-option/?limit=${obj?.limit ?? 30}&offset=${obj?.offset ?? 0}`);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const downloadGenerateOptionsList = createAsyncThunk('/go/downloadGenerateOptionsList', async (obj) => {
    try {
        const response = await axios.get(`/generate-option/download?from=${obj?.fromDate}&to=${obj?.toDate}&type=${obj?.type}`, {
            responseType: 'blob' // Set responseType to 'blob' to handle binary data
        });

        // Create a Blob object from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `generated_options_list.${obj?.type}`; // Specify the filename
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);

        return null;
    } catch (e) {
        return e;
    }
});
export const downloadGenerateOptionsListById = createAsyncThunk('/go/downloadGenerateOptionsListById', async (obj) => {
    try {
        const response = await axios.get(`/generate-option/download/${obj?.id}?type=${obj.type}`, {
            responseType: 'blob' // Set responseType to 'blob' to handle binary data
        });

        // Create a Blob object from the response data
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a temporary URL for the Blob object
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = `${obj.fileName.replace(/\s/g, '_').toLowerCase()}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${obj.type}`; // Specify the filename
        document.body.appendChild(a);
        a.click();

        // Cleanup
        window.URL.revokeObjectURL(url);

        return null;
    } catch (e) {
        return e;
    }
});

export const getGenerateOptionsById = createAsyncThunk('/go/getGenerateOptionsById', async (id) => {
    try {
        const response = await axios.get(`/generate-option/${id}`);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const getGenerateOptionsByIdPreview = createAsyncThunk('/go/getGenerateOptionsByIdPreview', async (id) => {
    try {
        const response = await axios.get(`/generate-option/preview/${id}`);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

export const postGenerateOptions = createAsyncThunk('/go/postGenerateOptions', async (obj) => {
    try {
        const response = await axios.post(`/generate-option`, obj);
        const { data } = response;
        return data;
    } catch (e) {
        dispatch(
            openSnackbar({
                open: true,
                message: 'something went wrong',
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: true
            })
        );
        return e;
    }
});

export const patchUpdateGenerateOptions = createAsyncThunk('/go/patchUpdateGenerateOptions', async ({ option, id }) => {
    try {
        const response = await axios.patch(`/generate-option/${id}`, option);
        const { data } = response;
        return data;
    } catch (e) {
        return e;
    }
});

const initialValue = {
    data: null,
    isFetching: false,
    errorMessage: '',
    isError: false
};

const initialState = {
    error: null,
    sharedOutputList: { ...initialValue, data: Array(1), pagination: { count: 10, offset: 0, limit: 10 } },
    generatedQuoteData: { ...initialValue, data: [] },
    generatedQuoteDataFake: {
        ...initialValue,
        data: {
            id: '3a3aa145-945a-4798-89c1-7178f420cf85',
            title: 'Generated Options at Nov 30, 2022 4:12 PM',
            expiry: '2022-12-09T22:16:00.000Z',
            options: [
                {
                    id: 0,
                    media: [],
                    address: '1262 Defense Highway, Gambrills, MD, 21054',
                    contact: 'Donna Shavatt | (410) 643-4344',
                    ratings: null,
                    summary: 'Y',
                    roomType: null,
                    monthlyCost: 0,
                    communityFee: 0,
                    communityName: 'Arcadia of Crofton I'
                },
                {
                    id: 1,
                    media: [],
                    address: '8909 Reisterstown Road, Pikesville, MD, 21208',
                    contact: 'Laura Ungar  | (410) 415-5600',
                    ratings: null,
                    summary: 'N',
                    roomType: null,
                    monthlyCost: 0,
                    communityFee: 0,
                    communityName: 'Arden Courts Of Pikesville'
                },
                {
                    id: 2,
                    media: [],
                    address: '4301 Knowles Avenue, Kensington, MD, 20895',
                    contact: 'Melissa | (301) 493-7881',
                    ratings: null,
                    summary: 'N',
                    roomType: null,
                    monthlyCost: 0,
                    communityFee: 0,
                    communityName: 'Arden Courts Of Kensington'
                },
                {
                    id: 3,
                    media: [],
                    address: '9885 Greenbelt Road, Lanham, MD, 20706',
                    contact: 'Torshie Brice | (301) 486-1590',
                    ratings: null,
                    summary: 'Y',
                    roomType: null,
                    monthlyCost: 0,
                    communityFee: 0,
                    communityName: 'Arbor Terrace Senior Living'
                }
            ],
            createdAt: '2022-11-30T11:12:07.518Z',
            updatedAt: '2022-12-01T11:57:58.000Z',
            deletedAt: null,
            user: {
                id: 12,
                firstName: 'Admin',
                lastName: 'SS',
                password: '$2a$10$8XwPOnUQUFu7yCOKJqTKoeieiwn7TnfN4eMjuCRr6l5tyZzhMvRwS',
                passwordResetToken: null,
                email: 'admin@silverstay.com',
                type: 'admin',
                createdAt: '2022-11-28T08:20:25.122Z',
                updatedAt: '2022-11-28T08:20:25.122Z',
                deletedAt: null
            }
        }
    },
    rows: [],
    columns: [],
    // for pagination
    pageSize: 9,
    currentPage: 1,
    totalPages: 10
    // -----------
};

const slice = createSlice({
    name: 'sharedoutput',
    initialState,
    reducers: {
        setCurrentPage(state, { payload }) {
            state.currentPage = payload;
        },
        setTotalPages(state, { payload }) {
            state.totalPages = payload;
        },
        setPageSize(state, { payload }) {
            state.pageSize = payload;
        },
        addItemsToSharedOutputList(state, { payload }) {
            const schema = {
                id: payload?.id,
                link: `${window.location.origin}/shared/${payload?.id}`,
                user: payload.user,
                title: payload.title,
                created_date: moment().format('lll'),
                expiry_date: payload.expiry_date ? new Date(payload.expiry_date) : null,
                records: payload.records.map((record) => ({
                    ...record
                }))
            };
            state.sharedOutputList.unshift(schema);
        },
        setGeneratedOptionData(state, { payload }) {
            state.generatedQuoteData.data = payload;
        },
        updateSharedOutputList(state, { payload }) {
            state.sharedOutputList = state.sharedOutputList?.map((record) => {
                if (record.id === payload?.id) {
                    return {
                        ...record,
                        records: payload?.data
                    };
                }
                return record;
            });
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(postGenerateOptions.pending, (state) => {
                state.generatedQuoteData.isFetching = true;
                state.generatedQuoteData.isError = false;
                state.generatedQuoteData.errorMessage = '';
            })
            .addCase(postGenerateOptions.fulfilled, (state, { payload }) => {
                state.generatedQuoteData.data = payload;
                state.generatedQuoteData.isFetching = false;
            })
            .addCase(postGenerateOptions.rejected, (state, { payload }) => {
                state.generatedQuoteData.isFetching = false;
                state.generatedQuoteData.isError = true;
                state.generatedQuoteData.errorMessage = payload;
            })
            // =============================getGenerateOptionsList=================================
            .addCase(getGenerateOptionsList.pending, (state) => {
                state.sharedOutputList.isFetching = true;
                state.sharedOutputList.isError = false;
                state.sharedOutputList.errorMessage = '';
            })
            .addCase(getGenerateOptionsList.fulfilled, (state, { payload }) => {
                state.sharedOutputList.data = payload?.data;
                state.sharedOutputList.pagination = payload?.pagination;
                state.sharedOutputList.isFetching = false;
            })
            .addCase(getGenerateOptionsList.rejected, (state, { payload }) => {
                state.sharedOutputList.isFetching = false;
                state.sharedOutputList.isError = true;
                state.sharedOutputList.errorMessage = payload;
            })
            // =============================getGenerateOptionsById=================================
            .addCase(getGenerateOptionsById.pending, (state) => {
                state.generatedQuoteData.isFetching = true;
                state.generatedQuoteData.isError = false;
                state.generatedQuoteData.errorMessage = '';
            })
            .addCase(getGenerateOptionsById.fulfilled, (state, { payload }) => {
                state.generatedQuoteData.isError = false;
                state.generatedQuoteData.errorMessage = {};
                state.generatedQuoteData.isFetching = false;
                state.generatedQuoteData.data = payload;
            })
            .addCase(getGenerateOptionsById.rejected, (state, { payload }) => {
                state.generatedQuoteData.isFetching = false;
                state.generatedQuoteData.isError = true;
                state.generatedQuoteData.errorMessage = payload;
            })
            // =============================getGenerateOptionsByIdPreview=================================
            .addCase(getGenerateOptionsByIdPreview.pending, (state) => {
                state.generatedQuoteData.isFetching = true;
                state.generatedQuoteData.isError = false;
                state.generatedQuoteData.errorMessage = '';
            })
            .addCase(getGenerateOptionsByIdPreview.fulfilled, (state, { payload }) => {
                if (payload.statusCode === 410) {
                    state.generatedQuoteData.isFetching = false;
                    state.generatedQuoteData.isError = true;
                    state.generatedQuoteData.errorMessage = payload;
                } else {
                    state.generatedQuoteData.isError = false;
                    state.generatedQuoteData.errorMessage = {};
                    state.generatedQuoteData.isFetching = false;
                    state.generatedQuoteData.data = payload;
                }
            })
            .addCase(getGenerateOptionsByIdPreview.rejected, (state, { payload }) => {
                state.generatedQuoteData.isFetching = false;
                state.generatedQuoteData.isError = true;
                state.generatedQuoteData.errorMessage = payload;
            })
            // =============================patchUpdateGenerateOptions=================================
            .addCase(patchUpdateGenerateOptions.pending, (state) => {
                state.generatedQuoteData.isFetching = true;
                state.generatedQuoteData.isError = false;
                state.generatedQuoteData.errorMessage = '';
            })
            .addCase(patchUpdateGenerateOptions.fulfilled, (state, { payload }) => {
                state.generatedQuoteData.data = payload;
                state.generatedQuoteData.isFetching = false;
            })
            .addCase(patchUpdateGenerateOptions.rejected, (state, { payload }) => {
                state.generatedQuoteData.isFetching = false;
                state.generatedQuoteData.isError = true;
                state.generatedQuoteData.errorMessage = payload;
            });
    }
});

export const sharedOutputSelector = (state) => state.sharedoutput;
export default slice.reducer;
export const { addItemsToSharedOutputList, updateSharedOutputList, setGeneratedOptionData, setCurrentPage, setTotalPages, setPageSize } =
    slice.actions;
// ----------------------------------------------------------------------
